import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ImageThanksKey'
})
export class ImagesThanksKeyPipe implements PipeTransform {

  transform(language: string): string {
    const URL = "../../../../assets/img/thanks/";
    return `${URL}clave.png`;
  }
}
