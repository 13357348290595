import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ICheckout } from 'src/app/interfaces/IShoppingCart';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {
  numberProducts$ : BehaviorSubject<number> = new BehaviorSubject(0);
  uriApiOrder: string = environment.gluky.ordersApiBasePatch;
  api_key: any;

  constructor(private http: HttpClient,
    private storageService: StorageService,
    private authService: AuthService) { }

  async getCart() {
    const claims = await this.storageService.get("gnx-claims");
    this.api_key = this.authService.apiKey;
    return new Promise((resolve, reject) =>
      this.http
        .get(
          `${this.uriApiOrder}/user-carts/${claims.uid}/carts/current`
        )
        .subscribe(
          (res: any) => {
            this.numberProducts$.next(res.totalQuantity);
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        )
    );
  }

  async createCart(product): Promise<any> {
    const claims = await this.storageService.get("gnx-claims");
    this.api_key = this.authService.apiKey

    return new Promise((resolve, reject) =>
      this.http
        .put(
          `${this.uriApiOrder}/user-carts/${claims.uid}/carts/current/items/${product.sku.raw}`,
          JSON.stringify({"quantity": product.quantity}),
        )
        .subscribe(
          async (res:any) => {
            this.numberProducts$.next(res.totalQuantity);
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        )
    );
  }

  async updateCart(items): Promise<any> {
    const claims = await this.storageService.get("gnx-claims");
    this.api_key = this.authService.apiKey
    return new Promise((resolve, reject) =>
      this.http
        .put(
          `${this.uriApiOrder}/user-carts/${claims.uid}/carts/current/items?key=${this.api_key}`,
          JSON.stringify({items}),
        )
        .subscribe(
          async (res: any) => {
            this.numberProducts$.next(res.totalQuantity);
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        )
    );
  }

  async checkoutData(body: ICheckout): Promise<any> {
    const claims = await this.storageService.get("gnx-claims");
    this.api_key = this.authService.apiKey
    return new Promise((resolve, reject) =>
      this.http
        .patch(
          `${this.uriApiOrder}/user-carts/${claims.uid}/carts/current/checkout-data?key=${this.api_key}`,
          JSON.stringify(body),
        )
        .subscribe(
          async (res: any) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        )
    );
  }

  async checkout(body: ICheckout): Promise<any> {
    const claims = await this.storageService.get("gnx-claims");
    this.api_key = this.authService.apiKey
    return new Promise((resolve, reject) =>
      this.http
        .post(
          `${this.uriApiOrder}/user-carts/${claims.uid}/carts/current/checkout?key=${this.api_key}`,
          JSON.stringify(body),
        )
        .subscribe(
          async (res: any) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        )
    );
  }
}
