import { Component, ComponentFactoryResolver, ComponentRef, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalInstructionsService } from '../modal-instructions/modal-instructions.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {

  @Input() set backgroundImage(image: string) {
    this._backgroundImage = image;
  }
  @Input() set hasModal(hasModal: boolean) {
    this._hasModal = hasModal;
  }

  @Input() set modalComponent(modalComponent: Component) {
    this._modalComponent = modalComponent;
  }

  @Input() set modalData(modalData: any) {
    this._modalData = modalData;
  }
  @Input() set modalHeight(height: string) {
    this._height = height;
  }

  _backgroundImage: string;
  _hasModal: boolean;
  _modalComponent: any;
  _modalData: any;
  _height: string;
  loadedImg = false;

  constructor( private modalInstructionsService: ModalInstructionsService) { }

  ngOnInit() { }

  async openModal() {
    if (this._hasModal) {
      this.modalInstructionsService.OpenModal(this._modalData, this._height, this._modalComponent)
    }
  }


}
