import { Component, OnInit } from '@angular/core';
import { LanguagesService } from 'src/app/services/languagesService/languages.service';
import { PointsService } from 'src/app/services/points/points.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import moment from "moment";
@Component({
  selector: 'app-info-general',
  templateUrl: './info-general.component.html',
  styleUrls: ['./info-general.component.scss'],
})
export class InfoGeneralComponent implements OnInit {
  date;
  loading: boolean = false;
  errorFlag: boolean = false;
  errorMessage: string;
  tab: number;
  data: any;
  headerTabs: string[] = [];
  localeStrings: any;
  pointsSort: any[];
  message_error: string;

  dateLastCredit
  dateNextExpired
  poinstEnable
  pointNextExpired = 0
  dateUpdated
  constructor(private utilsService: UtilsService, private languageService: LanguagesService, private pointsService: PointsService) { }


  async ngOnInit() {
    this.getData();
    this.getPoinstEnable();
  }

  getPoinstEnable() {
    this.utilsService.getUserPoints().then((result: any) => {
      this.poinstEnable = result;
    });

  }

  async getData() {
    const { base_url, api_key, token, accountId } = await this.pointsService.historyCredentials();
    if (base_url === null) {
      this.loading = false;
      this.errorFlag = true;
      this.errorMessage = this.getTextsIUPageHistoryPoints.message_error;
      return false;
    }
    if (base_url && api_key && token) {
      return this.getHistoryPoints(accountId);
    }
    return this.setErrorMessage();
  }

  setErrorMessage = () => {
    this.errorFlag = true;
    this.errorMessage = this.message_error;
  };
  async getHistoryPoints(accountId) {
    this.loading = true;
    this.errorFlag = false;

    try {
      this.data = await this.pointsService.getUserHistoryPoints(accountId);
      this.pointsSort = this.data ? this.data.data.sort((a, b) => new Date(b["createdAt"]).getTime() - new Date(a["createdAt"]).getTime()) : [];
      this.getInfoPoints(this.pointsSort);
      this.loading = false;
    } catch (err) {
      this.errorFlag = true;
      this.errorMessage = "Error";
      this.loading = false;
    }
  };


  getInfoPoints(data) {
    this.dateUpdated = this.pointsSort[0]?.createdAt
    const transCreditActived = this.pointsSort.filter((data) => {
      if (data.type == 'credit') {
        if (data.creditData) {
          if (data.creditData.expiryDatetime) {
            if (!data.creditData.expired && !data.creditData.depleted) {
              return true
            }
          }
        }
      }
    })

    const pointsSortExpired = transCreditActived ? transCreditActived.sort((a, b) => new Date(b.creditData["expiryDatetime"]).getTime() - new Date(a.creditData["expiryDatetime"]).getTime()) : [];

    this.dateNextExpired = pointsSortExpired[pointsSortExpired.length - 1]?.creditData?.expiryDatetime
    let pointsNextExpired = pointsSortExpired.map((transaccion) => {
      if (
        moment(transaccion.creditData?.expiryDatetime).format("YYYY-MM-DD") == moment(this.dateNextExpired).format("YYYY-MM-DD")) {
        return transaccion.creditData?.pointsAvailable
      }
    })
    pointsNextExpired.forEach((transaccionValue) => {
      if (transaccionValue) {
        this.pointNextExpired = this.pointNextExpired + transaccionValue
      }
    })

  }


  get getTextsIUPageHistoryPoints() {
    return this.languageService.textsIU.page_history_points;
  }


}
