import { Injectable } from '@angular/core';

import { BuildDetailsService } from './build-detail.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BuildDetails } from './build-details';

@Injectable({ providedIn: 'root' })
export class BuildDetailsHttpService {
  constructor(
    private http: HttpClient,
    private buildDetailService: BuildDetailsService
  ) {}

  fetchBuildDetails(): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
      }),
    };

    return new Promise((resolve: any) => {
      this.http
        .get<BuildDetails>('assets/buildVersion.json', httpOptions)
        .toPromise()
        .then((response) => {
          this.buildDetailService.buildDetails = response;
          resolve();
        });
    });
  }
}
