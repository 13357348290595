// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  appName: "Vibra",
  gmApiKey: "AIzaSyCtS13OW6URzLA_QtmduqVMHjpsVYNW9FQ",
  firebaseConfig: {
    apiKey: "AIzaSyCtS13OW6URzLA_QtmduqVMHjpsVYNW9FQ",
    authDomain: "gnx-vibra.firebaseapp.com",
    projectId: "gnx-vibra",
    storageBucket: "gnx-vibra.appspot.com",
    messagingSenderId: "573407140971",
    appId: "1:573407140971:web:e10aa5cd246e78c34fa52e",
    measurementId: "G-51C7TBKWMS"
  },
  gluky: {
    app_id: "gnx_vibra",
    programId: "gnx_vibra",
    companyId: "gluky_group",
    apikey: "AIzaSyCtS13OW6URzLA_QtmduqVMHjpsVYNW9FQ",
    apikeyPwa: "AIzaSyCllX0WVroeug1uxx4aYp3iRmy1liuoLnQ",
    apiKeyWeb: "AIzaSyCtS13OW6URzLA_QtmduqVMHjpsVYNW9FQ",
    apiKeyAndroid: "AIzaSyC2xhtAHOA7-mcHLDoEPteqSm7rTG1Zguk",
    usersApiBasePath: "https://users-api.glukynet.com/v2",
    ordersApiBasePatch: "https://orders-api.glukynet.com/v1",
    pointsApiBasePath: "https://bank-api.glukynet.com/v1",
    pointsNewApiBasePath: "https://pointz-main-test-us-east1-5202s9wg.ue.gateway.dev/main/v1",
    bffApiBasePath:"https://bffv2-cms.glukynet.com/v2",
    productsApiBasePath: "https://products-front-api.glukynet.com/v1",
    kpiFrontApiBasePath: "https://kpis-front-us-east1-21cvo6vg.ue.gateway.dev/v1",
    catalogueApiBasePath: "https://fb07883f71224ce39a631c312f9e280b.ent-search.us-east1.gcp.elastic-cloud.com",
    challengesStrapiApiBasePath:"https://bff-api.cms.glukynet.com/v1/contents/challenges",
    productsUrl: "https://products-front-api.glukynet.com/v1",
    recognitionsUrl: 'https://recognitions-front-us-east1-bryp80ke.ue.gateway.dev/v1',
    algoliaSearchUrl: 'https://instantsearch-us-east1-czsrv8lj.ue.gateway.dev/v1/front/simple/app/',
    bffApiBasePathV2: "https://bffv2-cms.glukynet.com/v2",
    cmsBffApiBasePath:"https://cms-bff-v3-2kntzite.ue.gateway.dev/v3",
    algoliaSearch: 'https://instant-search-us-east1-dchwf728.ue.gateway.dev/v1/front/simple/app/',
    cloudinaryUrl: 'https://res.cloudinary.com/glukygroup/'
  },
  country: "co",
  img_profile_default:
    "https://firebasestorage.googleapis.com/v0/b/gnx-mvp.appspot.com/o/defaults%2Fdefault-user.jpg?alt=media&token=15fbfa41-6ba2-4a88-8530-e5e5f41e45a4",
  phoneIndicator: "+57",
  clevertapAccountId: "4R6-87K-895Z",
  pathNotification: "https://notifications-api.glukynet.com/v1",
  dinamycLink: {
    url: "https://mvpimproved.page.link/C1mr",
    packageName: "com.gluky.gnxvibra",
  },
  geochallenges: {
    proximityRadiusInMeters: 800
  },
  saml: {
    provider: 'saml.bancolombia',
    url: 'https://gnx-bancolombia-reconocerte.firebaseapp.com/__/auth/handler'
  },
  emblu: {
    endpoint: "https://us-central1-gnx-vibra.cloudfunctions.net/getEmblueToken",
    associatePushToEmail: "https://us-central1-gnx-vibra.cloudfunctions.net/setAssociatePushToEmail",
    newContact: "https://us-central1-gnx-vibra.cloudfunctions.net/setNewContact"
  },
  frontendPuntoRedApi:'https://puntored-frontend-stg-us-east1-1g6o62sh.ue.gateway.dev/v1',
  public_id_cloudinary: '/Brands/Brands-test/',
  cloudinary_config: {
    cloud_name: 'glukygroup',
    apiKey: '112786663666772',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.