import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
@Input() label : string
@Input() value : any
@Input() title :string
//@Input() viewPercent :boolean
@Input() viewPercent :any
  constructor() { }

  ngOnInit() {
   //console.log("viewPercent ==",this.viewPercent);
   //console.log("value == ",this.value);
  }

}
