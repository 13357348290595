import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { Platform } from "@ionic/angular";

@Component({
  selector: "app-bottom-sheet",
  templateUrl: "./bottom-sheet.component.html",
  styleUrls: ["./bottom-sheet.component.scss"],
})
export class BottomSheetComponent implements OnInit, OnChanges {
  @Input() state: "open" | "close";
  @Input() viewButton = true;
  @Output() stateEvent: EventEmitter<any> = new EventEmitter();
  constructor(private platform:Platform) {}

  ngOnInit() {
    if (this.state === "open") {
      this.open();
    } else {
      this.close();
    }
  }
  ngOnChanges(changeState: any) {
    if (changeState.state.currentValue === "open") {
      return this.open();
    }
    if (changeState.state.currentValue === "close") {
      return this.close();
    }
  }

  //currentPosition of the swipe
  currentPosition;

  // height of the bottom sheet div
  height;
  //minimunThreshold
  minimunThreshold;
  startPosition;

  open() {
    // @TODO Cambio de estilos
    (<HTMLStyleElement>(
      document.querySelector(".gnx-bottomSheet__bg")
    )).style.display = "block";
    (<HTMLStyleElement>(
      document.querySelector(".gnx-bottomSheet")
    )).style.display = "block";
    this.stateEvent.emit("open");
    if(this.platform.is("desktop")){
      (<HTMLStyleElement>document.querySelector(".gnx-bottomSheet")).style.width =
        "45vw";
    } else {
      (<HTMLStyleElement>document.querySelector(".gnx-bottomSheet")).style.width =
        "90vw";
    }
    this.stateEvent.emit("open");
  }

  close() {
    this.currentPosition = 0;
    this.startPosition = 0;

    (<HTMLStyleElement>(
      document.querySelector(".gnx-bottomSheet")
    )).style.transform = "translate3d(0, 0, 0);";
    (<HTMLStyleElement>(
      document.querySelector(".gnx-bottomSheet__bg")
    )).style.display = "none";
    (<HTMLStyleElement>(
      document.querySelector(".gnx-bottomSheet")
    )).style.display = "none";

    this.stateEvent.emit("close");
  }

  // touchMove(event: TouchEvent) {
  //   if (this.startPosition == 0) {
  //     this.startPosition = event.touches[0].clientY;
  //   }
  //   this.height = document.querySelector(".gnx-bottomSheet").clientHeight;

  //   let y = event.touches[0].clientY;
  //   this.currentPosition = y - this.startPosition;
  //   if (this.currentPosition > 0 && this.startPosition > 0) {
  //     (<HTMLStyleElement>(
  //       document.querySelector(".gnx-bottomSheet")
  //     )).style.transform =
  //       "translate3d(0px," + this.currentPosition + "px,0px)";
  //   }
  // }

  // touchEnd() {
  //   this.minimunThreshold = this.height - 200;

  //   if (this.currentPosition < this.minimunThreshold) {
  //     (<HTMLStyleElement>(
  //       document.querySelector(".gnx-bottomSheet")
  //     )).style.transform = "translate3d(0px, 0px, 0px);";
  //   } else {
  //     this.close();
  //   }
  // }
}
