import { PushNotificationsManagerService } from './../../components/push-notifications-manager/push-notifications-manager.service';
import { Injectable } from "@angular/core";
import firebase from "firebase/app";
import { AngularFireAuth } from "@angular/fire/auth";
import { GooglePlus } from "@ionic-native/google-plus/ngx";

//Services
import { ExceptionsService } from "src/app/exceptions/exceptions.service";
import { AlertService } from "../alert/alert.service";
import { AuthService } from "../auth/auth.service";
import { LoaderService } from "../loader/loader.service";
import { RouterService } from "../router/router.service";
import { StorageService } from "../storage/storage.service";
import { Platform } from "@ionic/angular";
import { TagsService } from "../tags/tags.service";
import { environment } from "src/environments/environment";
import { EmblueService } from "src/app/services/emblue/emblue.service";
import { FcmService } from "src/app/services/fcm/fcm.service";
import { first } from "rxjs/operators";
import { alertController } from '@ionic/core';
import { LanguagesService } from "src/app/services/languagesService/languages.service";
import { RemoteConfigService } from "src/app/services/remote-config/remote-config.service";
import { UtilsService } from 'src/app/services/utils/utils.service';

@Injectable({
  providedIn: "root",
})
export class AuthGnxService {
  actionCodeSettings = {
    // url: `https://gnx-mvp.firebaseapp.com/auth-confirmation?email=${args}`,
    url: environment.dinamycLink.url,
    android: {
      packageName: environment.dinamycLink.packageName,
      installApp: true,
      minimumVersion: "0.0.1",
    },
    handleCodeInApp: true,
    dynamicCodeInApp: environment.dinamycLink.packageName,
  };
  token: any;
  tokenPush: any;
  multiples_accounts: boolean = false;
  LOADER_ID = "login-with-link";

  constructor(
    private afAuth: AngularFireAuth,
    private authService: AuthService,
    private exceptionService: ExceptionsService,
    private alertService: AlertService,
    private loaderService: LoaderService,
    private storageService: StorageService,
    private routerService: RouterService,
    private googlePlus: GooglePlus,
    private platform: Platform,
    private tagsService: TagsService,
    private emblueService: EmblueService,
    private fcmService: FcmService,
    public languageService: LanguagesService,
    public remoteConfig: RemoteConfigService,
    private utilsService: UtilsService,
    private pushNotificationsManagerService : PushNotificationsManagerService
  ) {
    this.getMultiplesAccounts();
  }

  async loginWithGoogle(LOADER_ID) {
    this.loaderService.presentLoading(LOADER_ID);
    await this.authService.logout();
    var provider = new firebase.auth.GoogleAuthProvider();

    try {
      const result = await firebase.auth().signInWithPopup(provider);
      var credential = result.credential;
      var user = result.user;

      const actualToken = await this.authService.callFbToken();
      const tokenGNX = await this.authService.getAccessFromUsers(
        actualToken
      );
      await this.storageService.set("gnx-token", tokenGNX);
      this.authService.setCurrentTokenGnx(tokenGNX);
      await this.emlue();
      this.routerService.redirect("app/home");
      this.loaderService.cancelLoader(LOADER_ID);
    } catch (error) {
      this.loaderService.cancelLoader(LOADER_ID);
      var errorMessage = error.message;

      if (errorMessage === "Not found") {
        console.log("Usuario no existe en Gnx");
        this.alertService.presentAlert(
          "Correo electronico no encontrado en GNX"
        );
        this.routerService.redirect("auth");
        setTimeout(() => {
          this.routerService.redirect("register-gluky");
        }, 2100);
      }
    }

  }

  async validateLoginGoogle(responseGoogle, LOADER_ID) {
    this.authService.methodAuth = "Google";
    this.authService.fbTokenJWT = await this.authService.callFbToken();
    this.authService.emailGoogle = responseGoogle.user.email;

    try {
      const tokenGNX = await this.authService.getAccessFromUsers(
        this.authService.fbTokenJWT
      );
      await this.storageService.set("gnx-token", tokenGNX);
      this.authService.setCurrentTokenGnx(tokenGNX);
      await this.emlue();
      this.routerService.redirect("app/home");
      this.loaderService.cancelLoader(LOADER_ID);
    } catch (error) {
      if (error.error.message === "Not found") {
        await this.alertService.presentAlert(
          "Correo electronico no encontrado en GNX"
        );
        this.routerService.redirect("auth");
        setTimeout(() => {
          this.routerService.redirect("register-gluky");
        }, 2100);
        this.loaderService.cancelLoader(LOADER_ID);
      }
    }
  }


  async loginWithUserAndPassword(
    LOADER_ID,
    username,
    password,
    captcha?
  ) {
    this.loaderService.presentLoading(LOADER_ID);

    try {
      const userAuthFB = await this.authService.loginWithUserAndPassword(
        username,
        password
      );
      const tokenFB = await this.authService.callFbToken();
      const tokenGNX = await this.authService.getAccessFromUsers(tokenFB, captcha);
      if(Array.isArray(tokenGNX)){
        throw {code: 'USER_MULTIPLE'}; 
      }
      await userAuthFB.user.getIdToken(true);

      await this.storageService.set("gnx-token", tokenGNX);
      this.authService.setCurrentTokenGnx(tokenGNX);
      await this.loaderService.cancelLoader(LOADER_ID);
      /* this.tagsService.setEventTag(
        "login_page",
        "login_page_success",
        true,
        false,
        "email"
      ); */
     // this.tagsService.setTagFromEvent('login_page_success')
     // this.tagsService.setTagFromEvent('login_start_new_flow')
      await this.emlue();
      this.fcmService.initPush();
      
      return this.routerService.redirect("app/home");
      
    } catch (err) {
      await this.loaderService.cancelLoader(LOADER_ID);
      console.error('loginWithUserAndPassword catch (err)', err);
     // this.tagsService.setTagFromEvent('login_page_error');
      this.loaderService.cancelLoader(LOADER_ID);
      throw err;
    }
  }


  async signInWithCustomToken(token, captcha){
    try {
      firebase.auth().signInWithCustomToken(token).then(async (userFB)=>{
        console.log('firebase.auth().signInWithCustomToken(token) signInWithCustomToken userFB',userFB);
        
        const tokenFB = await this.authService.callFbToken();
        const tokenGNX = await this.authService.getAccessFromUsers(tokenFB, captcha);
        await userFB.user.getIdToken(true);
        await this.storageService.set("gnx-token", tokenGNX)
        .then((data)=>{
          console.log('storageService.set("gnx-token", tokenGNX) data',data);
          this.authService.setCurrentTokenGnx(tokenGNX);
        }).catch((err)=>{
          console.log('storageService.set("gnx-token", tokenGNX) err',err);
          
        });
        localStorage.setItem('gnx-token', tokenGNX);
        this.tagsService.setEventTag(
          "login_page",
          "login_page_success",
          true,
          false,
          "email"
        );
        await this.emlue();
        await this.pushNotificationsManagerService.getNotifications()
       // return this.routerService.redirect("app/home");
  
       console.log('userFB',userFB);
       console.log('tokenFB',tokenFB);
       
       return;
      }).catch( (err)=>{
        console.error('firebase.auth().signInWithCustomToken(token) signInWithCustomToken err',err);
        throw err;
      })
      
    } catch (err) {
      console.error('signInWithCustomToken catch (err)', err);
      
      this.tagsService.setEventTag(
        "login_page",
        "login_page_error",
        true,
        false,
        "email"
      );
      this.exceptionService.renderError(err);
    }
  }

  redirectToRegister() {
    this.authService.methodAuth = "UserAndPassword";
    this.routerService.redirect("login-chat");
  }

  async sendEmailConfirmation(auth, args) {
    console.log("email confirmation");

    await auth.user.sendEmailVerification(this.actionCodeSettings);
  }

  public resetPassword(email: string): Promise<any> {
    const auth = firebase.auth();
    return auth
      .sendPasswordResetEmail(email, this.actionCodeSettings)
      .catch((error) => {
        console.log("error al enviar correo para cambio de contraseña", error)
        throw new Error(error);
      }
      );
  }

  async emlue() {
    await this.fcmService
      .getUserInfoUser()
      .pipe(first())
      .subscribe((user: any) => {
        if (user) {
          //console.log("user register",user);
          this.emblueService.getToken().then(async (token: any) => {
            let user_email = (user.email) ? user.email : 'mobile_' + user.phone.trim() + '@nomail.com';
            this.token = token;
            this.tokenPush = this.fcmService.tokenFcm;
            var phone_user = this.emblueService.validationPhone(user.phone);
            let editCustomFields =
              "telefono_1:|:" +
              phone_user +
              ":|:1|||nombre:|:" +
              user.name +
              ":|:1|||apellido:|:" +
              user.lastnames +
              ":|:1|||id_contacto:|:" +
              user.gnxCredential1 +
              ":|:1|||email_secundario:|:" +
              user_email +
              ":|:1|||push_fb:|:" +
              this.tokenPush +
              ":|:1";
            //console.log("editCustomFields",editCustomFields);
            //console.log("tokens",   this.token, " fcm: ", this.tokenPush);
            this.emblueService
              .setAssociatePushToEmail(
                user_email,
                this.token,
                this.fcmService.tokenFcm
              )
              .then((data) => {
                console.log("setAssociatePushToEmail", data);
                this.emblueService
                  .setNewContact(user_email, editCustomFields, this.token)
                  .then((result) => {
                    console.log("setNewContact", result);
                  });
              });
          });
        }
      });
  }

  get getTextsPageLogin() {
    return this.languageService.textsIU.page_login_multiple_account.title
  }

  async getMultiplesAccounts() {
    return this.remoteConfig.getMultiplesAccounts().subscribe((res) => {
      this.multiples_accounts = res;
    });
  }

  //validation multiples account
  async selectUsername(dataUsers): Promise<boolean> {
    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>(resolve => {
      resolveFunction = resolve;
    });
    var username;
    var title = (this.languageService.textsIU.page_login_multiple_account.title) ?? 'Selecciona una cuenta.';
    const inputs = dataUsers.map((data, index) => ({
      name: `radio${index}`,
      type: 'radio',
      label: data,
      value: data,
      handler: () => {
        username = data;
      }
    }));
    const alert = await alertController.create({
      cssClass: 'my-custom-class',
      header: title,
      inputs: inputs,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            resolveFunction(false);
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Enviar',
          handler: async () => {
            console.log('Confirm Ok, username: ' + username);
            await this.storageService.set('username', username);
            resolveFunction(true);
          }
        }
      ]
    });
    await alert.present();
    return promise;
  }

  async sendEmailLink(email: string): Promise<any> {
    console.log("email",email);
    const actionCodeSettings = {
      url: 'https://mvpgnx.page.link/4vDS',
      handleCodeInApp: true
    };

    try {
      await this.afAuth.sendSignInLinkToEmail(
        email,
        actionCodeSettings
      );
      console.log("email send");
      // this.alert.show("Se ha enviado un correo para que puedas iniciar sesión en la plataforma.")
       window.localStorage.setItem('emailForSignIn', email);
      // this.emailSent = true;
    } catch (err) {
      console.log("err.message", err.message);
      //this.errorMessage = err.message;
    }
  }



  async confirmSignIn(url) {
    try {
      if (this.afAuth.isSignInWithEmailLink(url)) {
        let email = window.localStorage.getItem('emailForSignIn');

        // If missing email, prompt user for it
        if (!email) {
          //this.error = false;
          return false;
        }

        // Signin user and remove the email localStorage
        const result = await this.afAuth.signInWithEmailLink(email, url);
        window.localStorage.removeItem('emailForSignIn');
        return result;
      }
    } catch (err) {
      console.log("error",err);
      //this.errorMessage = err.message;
      return err.message;
    }
  }


  async loginUserWithLink() {
    this.loaderService.presentLoading(this.LOADER_ID);
    try {
      const tokenFB = await this.authService.callFbToken();
      var tokenGnx = await this.authService.getAccessFromUsers(tokenFB);
      console.log("auth services tokenGNX", tokenGnx);

      //validation multiples accounts
      if (this.multiples_accounts && Array.isArray(tokenGnx)) {
        this.loaderService.cancelLoader(this.LOADER_ID);
        var confirm = await this.utilsService.selectUsername(tokenGnx);
        if (confirm) {
          var username = await this.storageService.get('username');
          console.log("username", username);
          tokenGnx =  await this.authService.getAccessFromUsers(tokenFB,username);
        }
      }
      
      this.authService.setCurrentTokenGnx(tokenGnx);
      await this.saveTokenGNX(tokenGnx);
      this.loaderService.cancelLoader(this.LOADER_ID);
      this.tagsService.setEventTag('otp', 'login_page_success', true, false, 'phone');
      return this.routerService.redirect("app/home");
    } catch (err) {
      console.log("err", err);
      this.tagsService.setEventTag('otp', 'login_page_error', true, false, 'phone')
      this.loaderService.cancelLoader(this.LOADER_ID);
      this.exceptionService.renderError(err);
    }
  }

  async saveTokenGNX(tokenGNX: any) {
    this.loaderService.cancelLoader(this.LOADER_ID);
    if (tokenGNX.match) {
      await this.storageService.set("gnx-token", tokenGNX);
    }
    try {
      await this.emblue();
    } catch (error) {
      console.log("error emblu set", error);
    }
    return this.routerService.redirect("app/home");
  }

  async emblue() {
    await this.fcmService.getUserInfoUser().pipe(first()).subscribe((user: any) => {
      if (user) {
        this.emblueService.getToken().then(async (token: any) => {
          let user_email = (user.email) ? user.email : 'mobile_' + user.phone.trim() + '@nomail.com';
          this.token = token;
          this.tokenPush = this.fcmService.tokenFcm;
          var phone_user = this.emblueService.validationPhone(user.phone);
          let editCustomFields = "telefono_1:|:" + phone_user + ":|:1|||nombre:|:" + user.name + ":|:1|||apellido:|:" + user.lastnames + ":|:1|||id_contacto:|:" + user.gnxCredential1 + ":|:1|||email_secundario:|:" + user_email + ":|:1|||push_fb:|:" + this.tokenPush + ":|:1";
          this.emblueService.setAssociatePushToEmail(user_email, this.token, this.fcmService.tokenFcm).then(data => {
            console.log("setAssociatePushToEmail", data);
            this.emblueService.setNewContact(user_email, editCustomFields, this.token).then(result => {
              console.log("setNewContact", result);
            });
          });
        });
      }
    });
  }

}
