import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImgIconsPipe } from "./img-icons.pipe";
import { ImageThanksPipe } from "./images-thanks.pipe";
import{ ImagesThanksKeyPipe} from "./imges-thanks-key.pipe"
import { DateHumanizePipe } from './date-humanize.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SrcImagePipe } from './srcImage/src-image.pipe';
import { ParseStatesPipe } from './parse-states.pipe';

@NgModule({
  declarations: [ImgIconsPipe, ImageThanksPipe, ImagesThanksKeyPipe,DateHumanizePipe, SafeHtmlPipe, SrcImagePipe, ParseStatesPipe],
  exports: [ImgIconsPipe, ImageThanksPipe, ImagesThanksKeyPipe, DateHumanizePipe, SafeHtmlPipe,SrcImagePipe,ParseStatesPipe],
  imports: [CommonModule],
})
export class PipesModule {}
