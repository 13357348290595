import { Component, Input, OnInit } from '@angular/core';
import CameraPhoto, { FACING_MODES } from 'jslib-html5-camera-photo';
import { Platform } from '@ionic/angular';
import { InvoicesService } from 'src/app/services/invoices/invoices.service';


@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss'],
})
export class CameraComponent implements OnInit {
  //@ViewChild('slides') slides: slide
  hideVideo = true
  slidesOptions = {
    initialSlide: 0,
    direction: 'horizontal',
    speed: 300,
    spaceBetween: 8,
    slidesPerView: 2.8,
    freeMode: true,
    loop: false
  };;
  element!: HTMLElement;
  @Input() inputProps: {
    name: string;
    onChange: (value) => void;
    onBlur: () => void;
    onFocus: () => void;
  };
  @Input() onClose: () => void;
  @Input() limitPhotos = 1; 
  cameraStarted: boolean = true;
  switchMode: boolean = false;
  cameraPhoto: CameraPhoto;
  videoElement!: HTMLVideoElement;
  showPreview = false;
  srcPreview = "";
  previewImages = [];

  constructor(public platform: Platform,
    public invoicesService: InvoicesService) {}

  ngOnInit() {
    (el: HTMLVideoElement) => (this.videoElement = el);
    this.inputProps.onFocus();
    let videoCamera = document.getElementById('videoCamera') as HTMLVideoElement;
    this.cameraPhoto = new CameraPhoto(videoCamera);
    this.startCamera();
  }

  get isIos(){
    return this.platform.is("ios");
  }

  async startCamera(idealFacingMode: typeof FACING_MODES.USER | typeof FACING_MODES.ENVIRONMENT = FACING_MODES.ENVIRONMENT) {
    this.cameraPhoto
      .startCamera(idealFacingMode, { width: 640, height: 480 })
      .then(() => {
        this.cameraStarted = true;
        this.hideVideo = false
      })
      .catch(error => {
        this.cameraStarted = false;
        this.hideVideo = true
      });
  }

  // pickImages(){
  //   const options: ImageOptions = {
  //     source: CameraSource.Photos,
  //     resultType: CameraResultType.DataUrl
  //   }

  //   Camera.getPhoto(options).then((res)=> {
  //       let base64Image = res.dataUrl;
  //       this.previewImages.push(base64Image);     
  //   })
  // }

  async changeMode() {
    this.switchMode = !this.switchMode;
    const facingMode = this.switchMode ? FACING_MODES.USER : FACING_MODES.ENVIRONMENT;
    this.startCamera(facingMode);
  }

  async takePhoto() {
    this.previewImages.push(this.srcPreview);
    this.showPreview = false;
    this.srcPreview = "";
    this.startCamera();
  }

  async validatePhoto() {
    const dataUri = this.cameraPhoto.getDataUri({});
    this.showPreview = true;
    this.srcPreview = dataUri;
    this.cameraStarted = false;
  }

  async cancelPhoto() {
    this.showPreview = false;
    this.srcPreview = "";
    this.startCamera();
  }

  async sendPhotos() {
    this.inputProps.onChange(this.previewImages);
    this.dismissModal();
  }

  deleteImage(index){
    this.previewImages.splice(index, 1)
  }

  async dismissModal() {
    if (this.cameraPhoto.stream) {
      await this.cameraPhoto.stopCamera();
    }
    this.inputProps.onBlur();
    this.onClose();
  }

}
